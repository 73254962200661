.navbar {
  background-color: white;
  width: 100%;
  height: 8vh;
}

.navbar .title {
  padding-left: 0px;
  color: #fafafa;
  font-size: 22px;
  margin: 4px 0px 8px;
  border-bottom: 1px solid gainsboro;

}

.ant-layout-header {
  padding: 0px 0px 0px 22px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 60px;
  background: white;
  height: 60px;
}

.site-layout-background {
  min-height: 700px;
}

#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

#siderNav {
  height: 100%;
  background: #ffffff !important;
  /* margin-top: -4px; */
}

#navBarMenu {
  height: 92vh;
}

.x-title {
  font-size: 18px;
  font-weight: bold;
}

.ant-switch {
  background-color: #00000040 !important;
}

.ant-switch-checked {
  background-color: var(--primary-color) !important;
}

.x-application-box .ant-card-actions > li {
  border: none;
}

.x-application-box .title {
  float: left;
  margin-left: 23px;
  font-weight: bold;
  color: black;
  font-size: 16px;
}

.x-application-box .action {
  float: right;
  margin-right: 23px;
  font-weight: bold;
  color: black;
  font-size: 16px;
  position: absolute;
}

.x-file-ul {
  margin-top: 5px;
  width: 100%;
}

.x-file-li {
  line-height: 30px;
}

.x-file-li:hover {
  background-color: #ebedf0;
}

.x-file-li span {
  color: #40a9ff;
  cursor: pointer;
  margin-left: 5px;
}

.x-header-logo-space {
  width: 64px;
  background: white;
  margin: 10px 0px 10px 17px;
  display: flex;
  height: 42px;
}

.x-task-count {
  color: var(--primary-color);
  position: absolute;
  top: 0;
  right: 5px;
}

.editMenu .ant-menu-item {
  width: 125px;
  text-align: center;
  height: 64px;
  line-height: 64px;
  font-size: 16px;
}

.x-header-layout .ant-menu-item:hover {
  border: none !important;
}
.x-header-layout .ant-menu-item-selected {
  font-weight: bold !important;
}

.x-header-layout .ant-menu-item-selected a {
  color: #090707 !important;
}

.x-header-layout .ant-menu-horizontal > .ant-menu-item a {
  color: #4B4848 !important;
}

.x-header-layout .ant-menu-item-selected:after {
  border: none !important;
}

.x-header-layout .ant-menu-item-selected span {
  display: block;
  position: relative;
}

.x-header-layout .ant-menu-item-selected span:after {
  content: '';
  position: absolute;
  left: 20%;
  right: 20%;
  bottom: 0;
  height: 4px;
  background-color: #006CF2;
}

.x-header-layout .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  border: none;
  background-color: rgba(243, 246, 252, 1);
  border-radius: 5px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.x-header-layout .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after {
  content: '';
  position: absolute;
  left: 20%;
  right: 20%;
  background-color: #006CF2;
}

.x-header-layout .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 0px;
}
.z-code-mirror .CodeMirror{height: auto !important;min-height: 300px;}
.x-task-mappingEdit .ant-collapse-header-text{width: 100%;}
.x-internationalize .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: var(--primary-color) !important;;
  border-color: var(--primary-color70) !important;;
}

.x-internationalize .ant-radio-group-solid .ant-radio-button-wrapper-checked:hover {
  color: #fff;
  background:var(--primary-color70) !important;;
  border-color: var(--primary-color70) !important;;
}



.z-map-search{
  width: 300px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
    height: 40px;
    padding: 0px 10px;
    border-radius: 5px;

}

.custom-content-marker {
  position: relative;
  width: 25px;
  height: 34px;
}

.custom-content-marker img {
  width: 100%;
  height: 100%;
}

.custom-content-marker .close-btn {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background: #ccc;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 15px;
  box-shadow: -1px 1px 1px rgba(10, 10, 10, .2);
}

.custom-content-marker .close-btn:hover{
  background: #666;
}

.map_panel {
  position: fixed;
  background-color: white;
  max-height: 90%;
  overflow-y: auto;
  top: 10px;
  right: 10px;
  width: 280px;
  border-bottom: solid 1px silver;
  z-index: 10;
}
.z-amap-autocomplete-input{
  border-radius: 30px !important;
  width: 300px !important;
  position: absolute !important;
  z-index: 10 !important;
  left: 18px !important;
}
.auto-item{
  padding: 7px !important;
  font-size: 13px !important;
}
.z-MapWidget-Modal .ant-modal-body{
  padding: 0px;
}
.ant-dropdown ul{
  z-index: 9999;
}
.ant-btn-dangerous{
  color: #ff4d4f !important;
}
.z-main-app-form .ant-form-item{
  margin-bottom: 6px !important;
}
.z-main-app-form .ant-checkbox-wrapper > span:last-child{
  font-weight: normal !important;
}
.z-rolelist-action{gap: 0px !important;}
.z-date-config .rc-select-item{height: 50px !important;}
.x-tools-frame .ant-tabs-nav-wrap{padding-left: 10px !important;height:60px !important}
.x-tools-frame .ant-collapse-header-text{font-size: 16px;}
.x-tools-frame .x-radio-group3 .ant-radio-button-wrapper {width: 77px !important; text-align: center !important;}
.x-tools-frame .x-radio-group2 .ant-radio-button-wrapper {width: 115px !important; text-align: center !important;}
.x-file-delete,.x-file-delete:hover,.x-file-delete:focus{border: none !important;box-shadow: none !important; background: transparent !important; }
.at-scan-input{flex-grow: 1;}