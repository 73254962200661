.bp3-input-left-container{
    top:50% !important;
    margin-top: -10px;
    padding-left: 3px;
    padding-right: 3px;
    color: gray !important;
    z-index: 100 !important;
}
.bp3-input-action .right-span{
    color:gray !important;
    padding-right: 3px !important;
    line-height: 30px;
}